@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  font-family: 'Poppins', sans-serif;
}

.main-timeline {
  position: relative;
}

.timeline {
  opacity: 0; /* Initially hidden */
  transform: translateX(50px); /* Start off-screen for larger screens */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Smooth transition */
}

.timeline.slide-in {
  opacity: 1; /* Fully visible */
  transform: translateX(0); /* Move to original position */
}
.card-body{
  align-self: center;
  
}
/* New class for sliding in from the bottom */
.timeline.slide-in-bottom {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Move to original position */
}

@media screen and (max-width: 600px) {
  .timeline {
    transform: translateY(50px); /* Start off-screen from bottom on smaller screens */
  }
}

.main-timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #939597;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.timeline {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: #939597;
  border: 5px solid #ac834f;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 20px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #ac834f;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 20px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ac834f transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* Header styles */
.timeline-header {
  
  text-align: center; /* Center the header */
  margin-bottom: 2rem; /* Space below the header */
  font-weight: 700;
}

.timeline-year {
  font-size: 2rem; /* Year font size */
  color: #ac834f;
}

.timeline-description {
  font-size: 1.5rem; /* Description font size */
  color: rgba(255, 255, 255, 0.822)
}

/* Media queries for responsive header */
@media screen and (max-width: 768px) {
  .timeline-header {
      font-size: 2.5rem; /* Smaller size for mobile */
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  .main-timeline::after {
      left: 31px;
  }

  .timeline-year {
      font-size: 1.3rem; /* Smaller size for mobile */
  }

  .timeline-description {
      font-size: 1rem; /* Smaller size for mobile */
  }

  .timeline {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
  }

  .timeline::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
  }

  .left::after,
  .right::after {
      left: 18px;
  }

  .left::before {
      right: auto;
  }

  .right {
      left: 0%;
  }
}

/* Card styles */
.card {
  background-color: white; /* Background color for the card */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  transition: transform 0.2s; /* Smooth transition for hover effects */
  border: 3px solid #ac834f; /* Gold border */
}

.card:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
  color: #000000;
}

/* Padding for card body */
.card-body {
  padding: 1.5rem; /* Padding inside card */
}

@media (max-width: 678px) {
  .timeline-header {
      font-size: 2.5rem; /* Reduce font size for small screens */
  }
}
