
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
   font-family: 'Poppins', sans-serif;
   max-height: auto;
}


.register-button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #c69960;
      color: white;
      text-decoration: none;
      border-radius: 5px;
      margin-top: 20px;
      
  }
  
  .register-button:hover {
      background-color: #b38800; /* Change background on hover */
      color: #fff; /* Change text color on hover */
  }
@media (max-width: 678px) and (min-width: 322px) {


.text-center img{
   margin-top: 0px;
}
.register-button {
   
   padding: 10px 15px;
   font-size: 16px;
   
}
}
@media (max-width: 320px)  {

  
   .register-button {
   
      padding: 5px 10px;
      font-size: 14px;
      
   }
   }
   
  