
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  font-family: 'Poppins', sans-serif;
  
}
.fade-in {
  opacity: 0;
  transform: translateY(250px); /* Slide in from below */
  animation: fadeIn 1s forwards; /* Animation duration and effect */
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.my-5{
    border: #ac834f solid 3px;
    padding: 30px 80px ;
    border-radius: 10px;
    background-color: rgb(239, 239, 239);
}
.about-image {
    border-radius: 10px; /* Optional: rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
  }
  
  .my-5 h2 {
    color: #ac834f; /* Optional: customize heading color */
    font-weight: bold; /* Optional: bold heading */
    text-align: center;
  }
  
  p {
   
    line-height: 2; /* Optional: improve readability */
    font-weight:500;
    
    
    
  }
  @media (max-width: 991.5px) and (min-width: 250px) {

    .my-5{
        border: #ac834f solid 3px;
        padding: 20px 10px ;
    }
  .my-5a{
    padding: 15px;
  }
  p {
   
    text-align: center;
    
    
  }
  
  }
  .my-5a img{
    border-radius: 10px;
  }
  /* src/About.css */

/* Ensure full width for smaller devices */
.text-column, .image-column {
  transition: all 0.3s ease; /* Smooth transition */
}
/* src/About.css */

/* Ensure full width for smaller devices */
.text-column,
.image-column {
    transition: all 0.3s ease; /* Smooth transition */
}

/* Media query for screen sizes between 679px and 991.5px */
@media (min-width: 679px) and (max-width: 991.5px) {
    .about-row {
        flex-direction: column; /* Stack items vertically */
    }

    .text-column {
        margin-bottom: 20px; /* Add space between text and image */
    }

    .about-image {
        width: 100%; /* Ensure the image is responsive */
        height: auto; /* Maintain aspect ratio */
    }
    .text-column{
      width: 800px;
      text-align: center;
    }
}
@media (min-width: 991.5px) and (max-width: 1200px) {
  .about-row {
      flex-direction: column; /* Stack items vertically */
  }

  .text-column {
      margin-bottom: 20px; /* Add space between text and image */
  }

  .about-image {
      width: 100%; /* Ensure the image is responsive */
      height: auto; /* Maintain aspect ratio */
  }
  .text-column{
    width: 800px;
    text-align: center;
  }
}
